<template>
    <default-template
        title="温熱・省エネ設備機器ポータルサイト(住宅版)"
        :is-visible-public="true"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="true"
        :is-visible-user-header="isOrganization"
        :is-visible-administrator-header="isAdministrator"
    >
        <div class="bl_contents_wrapper uq_deviceUpload">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">{{ deviceCategoryName }}ファイルアップロード</h2>
            </div>
            <error-list />
            <file-form
                @upload="upload"
                @back="back"
                :is-device="true"
                ref="jest-file-form"
                class="uq_fileForm_wraper"
                accept=".zip"
            />
        </div>
    </default-template>
</template>

<script>
/**
 * 機器ファイルアップロード
 *
 * [イベント処理]
 * upload...機器のバリデーションエンドポイントに入力されたファイルをアップロードし、結果を取得する
 * back...機器一覧に戻る
 */
import ErrorList from '@/components/molecules/ErrorList'
import FileForm from '@/components/organisms/FileForm.vue'
import device from '@/mixins/device'
import { mapActions } from 'vuex'
import { move } from '@/util'

export default {
    name:'OrganizationDeviceUpload',
    components:{
        ErrorList,
        FileForm,
    },
    mixins:[device],
    computed:{
        /**
         * 事業者ページか否か
         */
        isOrganization() {
            return this.$route.meta.routeType === 'organization'
        },
        /**
         * 管理者ページか否か
         */
        isAdministrator() {
            return this.$route.meta.routeType === 'administrator'
        },
    },
    methods:{
        ...mapActions({
            clearError:'error/clear',
            updateError:'error/update',
        }),
        /**
         * 機器のバリデーションエンドポイントに入力されたファイルをアップロードし、結果を取得する
         */
        async upload(){
            const loader = this.$loading.show()
            this.clearError()
            await this.$store.dispatch('file/check' + this.upperDeviceCategoryKey)
                .then(response => {
                    if (response.status == 200) {
                        move(this.$router, this.$route.path + '/confirm')
                    } else {
                        this.updateError(response)
                    }
                })
            loader.hide()
        },
        /**
         * 機器一覧に戻る
         */
        back(){
            this.clearError()
            const path = {
                cogenerations:'/organization/cogeneration_systems',
                hybridWaterHeaters:'/organization/hybrid_water_heaters',
                electricHeatPumps:'/organization/electric_heat_pumps',
                solarSystems:'/organization/solar_systems',
                solarWaterHeaters:'/organization/solar_water_heaters',
            }[this.$route.meta.deviceCategoryKey]
            move(this.$router, path)
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 800px;
    max-width: 800px;
}
.uq_deviceUpload{
    position: relative;
}
</style>