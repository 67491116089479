<template>
<div>
    <div class="boxcontainer bl_fileForm">
        <div class="bl_fileForm_selectFile">
            <h5 class="el_lv5Heading">１．アップロードするファイルを選択してください</h5>
            <app-button
                name="ファイルを選択"
                @click="selectFile"
                class="select_file inline"
            />
            <div class="selected_file bl_fileForm_selectFile_selectedFile">
                <input
                    id="uploadFile"
                    type="file"
                    style="visibility:hidden"
                    @change="fileChoice"
                    class="bl_fileForm_selectFile_selectedFile_input"
                    :accept="accept"
                />
                <p :class="{selected : fileName !== null}">
                    <span v-if="fileName == null">ファイルを指定してください</span>
                    <span v-else>ファイル名：{{fileName}}</span>
                </p>
            </div>
        </div>
        <div
            v-if="isDevice"
            class="bl_fileForm_selectFile"
        >
            <h5 class="el_lv5Heading">２．アップロードするファイルに設定されているパスワードの種類を選択してください</h5>
            <div class="bl_input_container uq_third_pass">
                <app-radio
                    v-model="passwordType"
                    value="organization"
                    label="事業者パスワード"
                />
                <app-radio
                    v-model="passwordType"
                    value="shared"
                    label="第三者機関パスワード"
                />
                <div
                    v-if="isPulldownShow"
                    ref="jest-file-form-pulldown"
                >
                    <app-select 
                        v-model="sharedPasswordId"
                        label="選択してください"
                        :vmodel-label="vModelLabel"
                        class="uq_passId"
                    >
                        <app-select-option
                            :value="null"
                            v-model="sharedPasswordId"
                        >
                            選択してください
                        </app-select-option>
                        <app-select-option
                            v-for="item in organizationNameList"
                            :key="'sharedPassWordID' + item.id"
                            :value="item.id"
                            v-model="sharedPasswordId"
                        >
                            {{item.name}}
                        </app-select-option>
                    </app-select>
                </div>
            </div>
        </div>
    </div>
    <div class="ly_buttonBox">
        <app-button
            :disabled="uploadDisabled"
            name="アップロード"
            @click="upload"
            class="upload_file"
            ref="jest-file-upload"
        />
        <app-button
            name="キャンセル"
            class="cancel"
            ref="jest-device-upload-back"
            @click="back"
        />
    </div>
</div>
</template>

<script>
/**
 * ファイル送信用フォーム
 * 
 * [プロパティ]
 * isDevice...機器情報のファイルを送信するフォームか否かの判定
 * 
 * [算出プロパティ]
 * isPulldownShow...第三者機関名称の一覧を表示するか否かの判定
 * 
 * [イベント処理]
 * selectFile...「ファイルを選択」ボタンが押されたら非表示のファイル用フォームをクリックし、ファイル選択ダイアログを開く
 * fileChoice...ファイルが選択されたらファイルそのものとファイル名をStoreに保存
 * upload...親コンポーネントで指定されたアップロード処理を発火
 */
import { mapGetters, mapActions } from 'vuex'
import { mapComputedProperties } from '@/util'

export default {
    name:'FileForm',
    props:{
        /**
         * 機器情報のファイルを送信するフォームか否かの判定
         */
        isDevice: {
            type: Boolean,
            default: false
        },
        /**
         * 機器情報のファイルを送信するフォームか否かの判定
         */
        accept: {
            type: String,
            default: null
        }
    },
    mounted(){
        this.updateOrganizationNameList()
    },
    computed:{
        ...mapGetters({
            data:'file/getAll',
        }),
        ...mapComputedProperties([
            'fileName',
            'file',
            'uploadDisabled',
            'passwordType',
            'sharedPasswordId',
            'organizationNameList',
        ]),
        /**
         * 第三者機関名称の一覧を表示するか否かの判定
         */
        isPulldownShow(){
            return this.passwordType === 'shared'
        },
        vModelLabel(){
            const selectedOrganization = this.organizationNameList.filter(x => x.id === this.sharedPasswordId)
            return selectedOrganization[0] ? selectedOrganization[0].name : ''
        }
    },
    methods:{
        ...mapActions({
            update:'file/update',
            updateOrganizationNameList:'file/updateOrganizationNameList',
        }),
        /**
         * 「ファイルを選択」ボタンが押されたら非表示のファイル用フォームをクリックし、ファイル選択ダイアログを開く
         */
        selectFile(){
            document.getElementById('uploadFile').click()
        },
        /**
         * ファイルが選択されたらファイルそのものとファイル名をStoreに保存
         */
        fileChoice(event){
            this.update({
                fileName:event.target.files[0]['name'],
                file:event.target.files[0],
            })
        },
        /**
         * 親コンポーネントで指定されたアップロード処理を発火
         */
        async upload(){
            await this.$emit('upload')
        },
        back(){
            this.$emit('back');
        }
    }
}
</script>

<style scoped>
.bl_fileForm{
    padding: 32px;
    border: 1px #C0D6DD solid;
}
.el_lv5Heading{
    display: inline-block;
    width: 700px;
}
.bl_fileForm_selectFile{
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 700px;
}
.bl_fileForm_selectFile:not(:last-of-type){
    width: 100%;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px #C0D6DD solid;
}
.bl_fileForm_selectFile_selectedFile{
    display: inline-flex;
    flex-wrap: nowrap;
}
.bl_fileForm_selectFile_selectedFile>p{
    min-width: 30em;
    margin: 4px 0 0 32px;
}
.bl_fileForm_selectFile_selectedFile_input{
    width: 0;
    height: 0;
}
.select_file{
    margin-left: 30px;
}
.uq_third_pass{
    min-height: 35px;
    margin-left: 30px;
}
.uq_passId{
    margin-right: 32px;
}
.uq_passId.select_wrapper >>> div.select_btn{
    min-width: 10em;
}
</style>